import React from 'react';
import './style.css';
import Lottie from 'react-lottie';
import LoadingAnimation from './loading-animation.json';
import Box from '@material-ui/core/Box';

const Loader = ({cls="", width, height}) => {

  const LodingOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
      // <div className={`page-loading ${cls ? cls : ''}`}><div className="dot-pulse" /></div>
      <Box display="flex" justifyContent="center" alignItems="center" width={width || "100vw"} height={height || "100vh"}>
        <Lottie
          options={LodingOptions}
          height={400}
          width={400}
          isClickToPauseDisabled        
        />
      </Box>
  );
};

export default Loader;
